import axios from "axios";
const TOKEN = "Bearer 5bb4cb5e7b93858d18c0e18f85fb1376";

export default {
  async handleLogin(email, password) {
    const data = new FormData();
    data.append("users_email", email);
    data.append("users_password", password);

    const response = await axios.post("/login/users", data, {
      headers: {
        Authorization: TOKEN,
      },
    });
    return response;
  },
};
