import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import axios from "axios";
/* Base url for the api */
axios.defaults.baseURL = "https://crm.sicurity.it//rest/v1/";

/* vue3-signature */
import Vue3Signature from "vue3-signature";

/* PWA Service Worker */
import "./registerServiceWorker";

/* PWA Elements for Camera */
import { defineCustomElements } from "@ionic/pwa-elements/loader";

if (
  localStorage.getItem("user") != null &&
  localStorage.getItem("ricordami") != null
) {
  //console.log(localStorage.getItem("ricordami"));
  router.replace("/tabs/");
}

const app = createApp(App)
  .use(IonicVue)
  .use(Vue3Signature)
  .use(router);

router.isReady().then(() => {
  app.mount("#app");

  defineCustomElements(window);
});
