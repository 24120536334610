/* eslint-disable no-console */

import { alertController } from '@ionic/vue';
import { register } from 'register-service-worker';


async function newVersion() {
  const alert = await alertController.create({
    header: "Aggiornamento applicazione",
    message: "É disponibile un nuovo aggiornamento dell'applicazione",
    backdropDismiss: false,
    buttons: [
      {
        text: "Installa",
        handler: () => {
          //Call logout function (delete localbasedb and redirect to login page)
          window.location.reload(true);
        },
      },
    ],
  });
  return alert.present();
}


if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.');
      //console.log(registration);
      //window.location.reload(true);
      newVersion();
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}


