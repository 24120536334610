import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tabs from '../views/Tabs.vue'
import Login from '../views/Login.vue';
//import ClienteDetail from '../views/ClienteDetail.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const user = localStorage.getItem("userInfo");
      const ricordami = localStorage.getItem("ricordami");
      if (user && ricordami) {
        next({ path: '/tabs/' });
      } else {
        next();
      }
    }
  },
  {
    path: '/tabs/',
    component: Tabs,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      /* TICKETS E DETT. TICKET */
      {
        path: 'tab1',
        //component: () => import('@/views/Tab1.vue'),
        component: () => import('@/views/Tab3.vue'), //Tickets --> tab 3
      },
      {
        path: "tab1/:id",
        name: 'TicketDetail',
        //component: () => import('@/views/ClienteDetail.vue')
        component: () => import('@/views/TicketDetail.vue') //Dett. tickets --> tab3/:id ticket
      },
      /* CLIENTE E DETT. CLIENTE (CON ANCHE DETT. COMMESSA) */
      {
        path: 'tab2',
        //component: () => import('@/views/Tab2.vue')
        component: () => import('@/views/Tab1.vue') //Clienti --> tab 1
      },
      {
        path: "tab2/:id",
        name: 'ClienteDetail',
        //component: () => import('@/views/InterventoDetail.vue')
        component: () => import('@/views/ClienteDetail.vue') //
      },
      {
        path: "tab2/:id/commessa/:id_commessa",
        name: 'CommessaDetail',
        component: () => import('@/views/CommessaDetail.vue')
      },
      /* INTERVENTO E DETT. INTERVENTO (CON FIRMA CLIENTE e OPERATORE) */
      {
        path: 'tab3',
        component: () => import('@/views/Tab2.vue'),
      },
      {
        path: "tab3/:id",
        component: () => import('@/views/InterventoDetail.vue')
      },
      {
        path: 'tab3/:id/firma-cliente',
        name: 'FirmaCliente',
        component: () => import('@/views/FirmaCliente.vue'),
        //props: true
      },
      {
        path: 'tab3/:id/firma-operatore',
        name: 'FirmaOperatore',
        component: () => import('@/views/FirmaOperatore.vue'),
        //props: true
      },
      /* PROFILO */
      {
        path: 'tab4',
        component: () => import('@/views/Tab4.vue')
      },
      {
        path: 'free-tickets',
        component: () => import('@/views/FreeTickets.vue'),
        props: true
      },
      {
        path: 'free-ticket-detail/:id',
        component: () => import('@/views/FreeTicketDetail.vue'),
        props: true
      }
    ]
    /*     children: [
          {
            path: '',
            redirect: '/tabs/tab1'
          },
          {
            path: 'tab1',
            component: () => import('@/views/Tab1.vue'),
          },
          {
            path: "tab1/:id",
            name: 'ClienteDetail',
            component: () => import('@/views/ClienteDetail.vue')
          },
          {
            path: "tab1/:id/commessa/:id_commessa",
            name: 'CommessaDetail',
            component: () => import('@/views/CommessaDetail.vue')
          },
          {
            path: 'tab2',
            component: () => import('@/views/Tab2.vue')
          },
          {
            path: "tab2/:id",
            component: () => import('@/views/InterventoDetail.vue')
          },
          {
            path: 'tab2/:id/firma-cliente',
            name: 'FirmaCliente',
            component: () => import('@/views/FirmaCliente.vue'),
            //props: true
          },
          {
            path: 'tab2/:id/firma-operatore',
            name: 'FirmaOperatore',
            component: () => import('@/views/FirmaOperatore.vue'),
            //props: true
          },
          {
            path: 'tab3',
            component: () => import('@/views/Tab3.vue'),
          },
          {
            path: "tab3/:id",
            component: () => import('@/views/TicketDetail.vue')
          },
          {
            path: 'tab4',
            component: () => import('@/views/Tab4.vue')
          },
          {
            path: 'free-tickets',
            component: () => import('@/views/FreeTickets.vue'),
            props: true
          },
          {
            path: 'free-ticket-detail/:id',
            component: () => import('@/views/FreeTicketDetail.vue'),
            props: true
          },
          {
            path: '/firma-cliente',
            component: () => import('@/views/FirmaCliente.vue'),
            props: true
          }
        ] */
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const user = localStorage.getItem("userInfo");
  //const ricordami = localStorage.getItem("ricordami");
  /*console.log(ricordami);
  console.log("!ricordami: ", !ricordami)
  console.log("!user: ", !user)*/
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (user == null) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next();
  }
})

export default router
