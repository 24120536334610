
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCheckbox,
  IonIcon,
  //IonNote,
  //IonSpinner,
  IonLoading,
  modalController,
  alertController,
  //toastController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import axios from "axios";
import md5 from "md5";
import { useRouter } from "vue-router";

import { eyeOutline } from "ionicons/icons";

import apiLogin from "../services/login";

import { openToast } from "../services/toast";
//Set user in indexedDB --> dbUser --> user
import { setUser } from "../services/localbase";
import PwaInstructionsVue from "../components/PwaInstructions.vue";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
    IonCheckbox,
    IonIcon,
    //IonNote,
    //IonSpinner,
    IonLoading,
  },
  setup() {
    const email = ref("");
    const password = ref("");
    const userData: string[] = reactive([]);
    const ricordami = ref(false);
    const loading = ref(false);
    const setOpen = (state: boolean) => (loading.value = state);

    const showPassword = ref(false);

    const router = useRouter();

    /*
     * Open PWA instructions page
     */
    async function openModal() {
      const modal = await modalController.create({
        component: PwaInstructionsVue,
      });
      return modal.present();
    }

    /*
     * Alert to confirm password recovery
     */
    /*     async function handlePasswordRecovery() {
      const alert = await alertController.create({
        header: "Recupero Password!",
        message: "Invia la richiesta per recuperare la tua password",
        buttons: [
          {
            text: "Annulla",
            role: "cancel",
            handler: () => {
              console.log("CHIUSUTRA ALERT RICHIESTA RECUPERO PASSWORD...");
            },
          },
          {
            text: "Invia richiesta",
            handler: () => {
              console.log("INVIO RICHIESTA RECUPERO PASSWORD...");
            },
          },
        ],
      });
      return alert.present();
    } */

    /*
     * Show / hide password
     */
    function togglePassword() {
      showPassword.value = !showPassword.value;
    }

    /**
     * Handle login
     */
    function doLogin() {
      loading.value = true;
      const hashPassword = md5(password.value.toString());
      const userEmail = email.value;
      const userPassword = hashPassword.toString();

      apiLogin
        .handleLogin(userEmail, userPassword)
        .then((response) => {
          if (response.data.status == 0 && response.data.data.length != 0) {
            userData.push(response.data.data);
            //save user data to localStorage and indexeddb
            localStorage.setItem("userInfo", JSON.stringify(userData[0]));
            if (ricordami.value) {
              localStorage.setItem("ricordami", `${ricordami.value}`);
            }
            setUser(response.data.data);

            //Clear input and redirect to homepage
            email.value = "";
            password.value = "";
            ricordami.value = false;
            loading.value = false;
            router.push("/tabs/");
          } else {
            openToast("Login fallito, controlla email e password", "danger");
          }
        })
        .catch((error) => {
          openToast("Login fallito, controlla email e password", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    return {
      email,
      password,
      doLogin,
      ricordami,
      loading,
      setOpen,
      openModal,
      eyeOutline,
      togglePassword,
      //handlePasswordRecovery,
      showPassword,
    };
  },
});
